import React from 'react'
import CenteredColumns from './../CenteredColumns'
import './styles.scss'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

function ContentCards(props) {
  return (
    <CenteredColumns>
      {props.people.map((item, index) => (
        <div
          className="column is-half-tablet is-one-quarter-desktop"
          key={index}
        >
          {item.image && (
            <a className="ContentCards__card card is-flex" href={item.url}>
              <div className="card-image">
                <figure className="image">
                  <img src={item.image} alt={item.title} style={item.style} />
                </figure>
              </div>
              <div className="card-content">
                <div className="content">
                  <h4>{item.title}</h4>
                  <p>{item.body}</p>
                </div>
              </div>
            </a>
          )}
          {!item.image && (
            <div className="ContentCards__card card is-flex equal-height">
              <div className="card-header">
                <div className="card-header-title">{item.title}</div>
              </div>
              <div className="card-content">
                <div className="content">{item.body}</div>
              </div>
              <div className="card-footer footer-bottom">
                <OutboundLink
                  className="button is-primary full-width"
                  href={item.url}
                >
                  {item.cta}
                </OutboundLink>
              </div>
            </div>
          )}
        </div>
      ))}
    </CenteredColumns>
  )
}

export default ContentCards
