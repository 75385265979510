import React from 'react'
import Section from '../Section'
import SectionHeader from '../SectionHeader'
import VideoEmbed from '../VideoEmbed'
import './styles.scss'
import NewsletterSection from '../NewsletterSection'

function VideoSectionFeature(props) {
  return (
    <div className="container">
      <Section color={props.color} size={props.size}>
        <div className="columns">
          <div className="column is-three-fifths">
            <VideoEmbed
              className="VideoSection__box box"
              url={props.embedUrl}
            />
          </div>
          <div className="column is-two-fifths">
            <NewsletterSection
              title={props.title}
              subtitle={props.subtitle}
              centered={false}
              size={4}
              buttonText={props.buttonText}
              subscribedMessage={props.subscribedMessage}
              inputPlaceholder={props.inputPlaceholder}
              formName={props.formName}
            />
          </div>
        </div>
      </Section>
    </div>
  )
}

export default VideoSectionFeature
