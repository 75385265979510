import React from 'react'
import { graphql } from 'gatsby'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
// import EmailForm from '../components/emailForm'
import ContentCardsSection from '../components/ContentCardsSection'
import VideoSectionFeature from '../components/VideoSectionFeature'
import PropTypes from 'prop-types'
import SEO from '../components/seo'
import Divider from '../components/Divider'

class BlogPostTemplate extends React.Component {
  render() {
    const siteData = this.props.data
    const featureData = this.props.pageContext.data
    const location = this.props.location
    const origin = 'https://www.akutehealth.com'
    const href = origin + location.pathname
    return (
      <>
        <Navbar
          color="white"
          spaced={true}
          data={siteData.allContentfulNavbar}
        />
        <SEO
          title={featureData.title}
          keywords={featureData.keywords}
          description={featureData.subtitle}
          type="article"
          image={'url(' + featureData.image.fluid.src + ')'}
        />
        {/* <div className="container"> */}
        <VideoSectionFeature
          color="white"
          size="small"
          title={featureData.title}
          subtitle={featureData.subtitle}
          embedUrl={featureData.youtubeLink}
          buttonText="Subscribe to Updates"
          subscribedMessage="Thanks for subscribing!"
          inputPlaceholder="Email Address"
          formName="subscribe"
        />
        <Divider color="light" />
        <ContentCardsSection
          color="white"
          size="small"
          title="Featured Content"
          subtitle=""
          cards={[
            {
              title: featureData.extraTitle,
              body: featureData.extraSubtitle,
              url: featureData.extraLink,
              cta: featureData.extraCta,
            },
            {
              title: 'Join our Facebook Group',
              body:
                'Join our Facebook group and help design the EHR of your dreams!',
              url: 'https://www.facebook.com/groups/AkuteHealth',
              cta: 'Join Group',
            },
            {
              title: 'Per-Patient Pricing',
              body:
                "We price per patient, rather than per physician. This makes our EHR much more affordable when you're first starting and incentivizes us to help you grow. Win win!",
              url: 'https://www.akutehealth.com/pricing',
              cta: 'Learn More',
            },
          ]}
        />
        {/* </div> */}
        <Footer
          color="light"
          size="medium"
          logo={siteData.allContentfulNavbar.edges[0].node.brandLogo}
          description={siteData.allContentfulFooter.edges[0].node.description}
          copyright={
            '© ' +
            siteData.allContentfulFooter.edges[0].node.companyName +
            ` ${new Date().getFullYear()}`
          }
          socialPages={siteData.allContentfulFooter.edges[0].node.socialPages}
          socialImages={siteData.allContentfulFooter.edges[0].node.socialImages}
          driftAppId={siteData.allContentfulFooter.edges[0].node.driftAppId}
        />
      </>
    )
  }
}

export default BlogPostTemplate

BlogPostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query {
    allContentfulNavbar {
      edges {
        node {
          brandLogo {
            fixed(width: 250) {
              width
              height
              src
              srcSet
            }
            fluid(maxWidth: 1000) {
              src
            }
          }
          links
        }
      }
    }
    allContentfulStyles {
      edges {
        node {
          childContentfulStylesButtonJsonNode {
            backgroundColor
            color
          }
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          companyName
          description
          companyContactEmail
          driftAppId
          socialPages
          socialImages {
            title
            fixed(width: 20) {
              width
              height
              src
              srcSet
            }
          }
        }
      }
    }
  }
`
